import i18n from "i18next";
import { reactI18nextModule } from "react-i18next";

import translationEN from '../translate/en.json';
import translationFR from '../translate/fr.json';
import translationPT from '../translate/pt.json';
import translationJA from '../translate/ja.json';

import { getUrlParam } from "./util";


// the translations
const resources = {
  en: {
    translation: translationEN
  },
  fr: {
    translation: translationFR
  },
  pt: {
    translation: translationPT
  },
  ja: {
    translation: translationJA
  }
};

const langcode = getUrlParam('langcode') || null;
if (langcode && (langcode in resources))
  localStorage.setItem("currentLang", langcode);

// const currentLang = "fr";
const currentLang = localStorage.getItem('currentLang') || 'en';  // Force 'en' if nothing in the localstorage yet

i18n
  .use(reactI18nextModule) // passes i18n down to react-i18next
  .init({
    resources,
    lng: currentLang,
  // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false // react already safes from xss
    }
  });

export default i18n;