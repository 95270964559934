import React , { Component } from 'react';

import '../../styles/App.css';
import ReactMarkdown from 'react-markdown';
import {API_URL, HEADER_API_URL} from '../../utils/config';

import HeaderHome from '../component/HeaderHome';
import BreakfastsMenu from '../component/BreakfastsMenu';

class Home extends Component {

  constructor(props){
    super(props);
  }

  state = {
    infos: null,
    base_url: null
  }

  componentDidMount() {
    fetch(API_URL.info,{
      method: 'GET',
      headers: {
        "Accept-Language": HEADER_API_URL.accept_language,
        "x-api-key": HEADER_API_URL.api_key
      },
    })
    .then((res) => {
      if (!this.state.base_url) {
        this.setState({ base_url: res.headers.get("content-base-url") });
        localStorage.setItem("base_url",this.state.base_url);
      }
      return res.json();
    }).then((data) => {
      if (!this.state.infos) {
        this.setState({ infos: data[0] }, () => {
          // console.log('resulthome',this.state.infos);
        })
      }
    })
    .catch(console.log)
  }


  render() {
    return (
      <div className="page-home">
        <HeaderHome BkgHeader={this.state.infos && this.state.infos.field_image.file.url} BaseUrl={this.state.base_url} TitleRoomService={this.state.infos && this.state.infos.title} PictoRoomService={this.state.infos && this.state.infos.field_activity_category.field_media_icon}/>
        <div className="content">
          <ReactMarkdown className="description-home" source={this.state.infos && this.state.infos.field_description} escapeHtml={false}/>
          <BreakfastsMenu MenuResult={this.state.infos && this.state.infos.field_blocks} BaseUrl={this.state.base_url} varT={this.props.varT}/>
        </div>
      </div>
    );
  }
}

export default Home;
  